import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgPhoneOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height="24px" viewBox="0 0 24 24" width="24px" {...props}>
    <path
      d="M5 4h4l2 5-2.5 1.5a11 11 0 0 0 5 5L15 13l5 2v4a2 2 0 0 1-2 2A16 16 0 0 1 3 6a2 2 0 0 1 2-2"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
  </svg>
);
const Memo = memo(SvgPhoneOutlineIcon);
export default Memo;
